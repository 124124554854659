<template>
	<eventsLayout>
		<div class="page_event_details">
			<div class="head_event" :class="{'no_images': !imageUrl}"><!-- no_images -->
				<div class="background" v-if="imageUrl" v-bind:style="{'background-image': 'url('+(imageUrl)+')'}"></div>
				<div class="head_name_festival" v-if="!imgs.length">{{ event.title }}</div>

				<v-container>
					<div class="head_images" v-if="imgs.length === 1">
						<div class="one_img">
							<img :src="imgs[0]" @click="showFullImage(0)" alt="">
						</div>
					</div>
					<div class="head_images" v-if="imgs.length >= 2">
						<div class="master">
							<img :src="imgs[0]" @click="showFullImage(0)" alt="">
						</div>
						<div class="slave">
							<img :src="imgs[1]" @click="showFullImage(0)" alt="">
							<span></span>
							<img :src="imgs[2]" @click="showFullImage(0)" alt="">
						</div>
						<button type="button" @click="showAllPhotos" class="show_all_photos" v-ripple>Show all photos</button>
					</div>
				</v-container>
			</div>
			<div class="event_description">
				<v-container>
					<div class="info_box">
						<h1 class="event_name">{{ event.title }}</h1>
						<h6 class="event_by">
							<span>by</span>
							<span v-if="eventDetails.owner"
							      @click="redirectToUserProfile(eventDetails.owner['@id'].split('/')[2], eventDetails.owner.nickname)">
                                {{ eventDetails.owner.nickname }}
                            </span>
						</h6>


						<!-- SETTINGS & GENRES -->
						<div class="event_subgenres">
							<template v-if="eventDetails.setting && eventDetails.setting.length">
								<span v-for="(setting, index) of eventDetails.setting" :key="index">{{ setting }}</span>
							</template>
							<template v-if="eventDetails.genre.length">
								<span v-for="(genre, index) of eventDetails.genre" :key="index">{{ genre }}</span>
							</template>
						</div>

						<!-- DATES -->
						<div class="event_date">
							<div class="day_">{{ eventDetails.dateStart | moment("dddd hh:mm") }} - {{ eventDetails.dateEnd | moment("dddd hh:mm") }}</div>
							<div class="month_">{{ eventDetails.dateStart | moment("MMMM DD") }} - {{ eventDetails.dateEnd | moment("MMMM DD") }}</div>
						</div>

						<!-- LOCATIONS -->
						<div class="event_location">
							<div class="location_label">Location</div>
							<div class="location_value">{{ JSON.parse(eventDetails.location).administrative_area_level_2 }},
								{{ JSON.parse(eventDetails.location).country }}
							</div>
						</div>

						<!-- TICKETS -->
						<div class="event_tickets" v-if="eventDetails.tickets && eventDetails.tickets.length">
							<div class="tickets_label">Tickets</div>
							<div class="item_ticket" v-for="ticket of eventDetails.tickets" :key="ticket.id">
								<div class="ticket_name">{{ ticket.title }}
									<template v-if="ticket.price">-</template>
									<span class="ticket_price" v-if="ticket.price"> ${{ ticket.price }}</span>
								</div>
								<div class="ticket_description">
									{{ ticket.description }}
								</div>
							</div>
						</div>
					</div>

					<div class="info_box">
						<div class="action_actions">
							<div class="interested">
								<div class="value">{{ eventDetails.statistic.pages }}</div>
								<button type="button" v-ripple>Interested</button>
							</div>
							<div class="share">
								<div class="value">{{ eventDetails.statistic.shared }}</div>
								<button type="button" v-ripple>Share</button>
							</div>
						</div>

						<template v-if="eventDetails.description.length">
							<div class="description_text" v-if="eventDetails.description.length < eventDetailsLength" v-html="eventDetails.description"></div>
							<template v-else>
                          <span class="description_text">
                            <p v-html="eventDetails.description.substring(0, eventDetailsLength)"></p>
	                          <!--                            <p>... </p>-->
                            <button type="button" v-if="eventDetails.description.length > eventDetailsLength" class="see_more"
                                    @click="seeMore">See more</button>
                          </span>
							</template>
						</template>

						<!--                    <div class="description_text">-->
						<!--                        {{ eventDetails.description }}-->
						<!--                        &lt;!&ndash;                    <div class="see_more">See More</div>&ndash;&gt;-->
						<!--                    </div>-->
					</div>
				</v-container>
			</div>

			<div class="_tabs">
				<v-container>
					<v-tabs v-model="tab">
						<v-tab v-for="tab in tabs" :key="tab">
							{{ tab }}
						</v-tab>
					</v-tabs>
				</v-container>
			</div>

			<div class="_tabs_content">
				<v-container>
					<v-tabs-items touchless v-model="tab">
						<v-tab-item>
							Announcements
						</v-tab-item>
						<v-tab-item>
							Discussion
						</v-tab-item>
					</v-tabs-items>
				</v-container>
			</div>


			<div class="all_photos" v-if="allPhotos">

				<div class="container">
					<div class="heading">
						<h1>{{ event.title }}</h1>
						<button type="button" @click="hideAllPhotos">Close</button>
					</div>

					<div class="wrapper_list_photo">
						<div v-for="(img, index) in imgs"
						     :key="index"
						     class="pic"
						     @click="showFullImage(index)">
							<img v-if="img" :src="img" alt="">
						</div>
					</div>

				</div>
			</div>

			<vue-easy-lightbox
				:visible="visibleFullImage"
				:imgs="imgs"
				:index="index"

				@on-prev="handlePrev"
				@on-next="handleNext"
				@hide="visibleFullImage = false">

				<!--        <template v-slot:prev-btn="{ prev }">-->
				<!--            <button @click="prev"> prev </button>-->
				<!--        </template>-->
				<!--        <template v-slot:next-btn="{ next }">-->
				<!--            <button @click="next">next</button>-->
				<!--        </template>-->
				<template v-slot:close-btn="{ close }">
					<button class="close" @click="close">Close</button>
				</template>

			</vue-easy-lightbox>
		</div>
	</eventsLayout>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import eventsLayout from "@/layouts/eventsLayout.vue";

export default {
	name: 'eventDetails',
	components: {
		eventsLayout
	},
	props: ['id'],
	data() {
		return {
			apiUrl: process.env.VUE_APP_API_URL,
			allPhotos: false,
			imgs: [],

			visibleFullImage: false,
			index: 0,

			imageUrl: '',
			tab: null,
			tabs: [
				'Announcements', 'Discussion'
			],
			eventDetails: '',
			eventDetailsLength: 600
		}
	},
	computed: {
		...mapGetters({
			eventsList: 'events/EVENTS',
			event: 'events/EVENT'
		})
	},
	created() {
		let eventData;
		eventData = this.eventsList.filter((event) => event.id === this.id);

		if (eventData && eventData.length) {
			this.eventDetails = eventData[0];
			this.imageUrl = `${this.apiUrl + eventData[0].images[0].contentUrl}`;

			for (let i of eventData[0].images) {
				this.imgs.push(this.apiUrl + i.contentUrl);
			}
		} else {
			this.getEventDetails();
		}
	},
	mounted() {

	},
	methods: {
		...mapActions({
			getEvent: 'events/GET_EVENT',
		}),
		redirectToUserProfile(userID, userName) {
			const routeData = this.$router.resolve({name: 'user-profile', params: {id: userID, name: window.toHumanFormat(userName)}});
			window.open(routeData.href, '_blank');
		},
		getEventDetails() {
			this.getEvent(this.id)
				.then(() => {
					if (!this.event.id) {
						this.$router.push({name: "404"});
					}
					this.eventDetails = this.event;
					this.imageUrl = `${this.apiUrl + this.event.images[0].contentUrl}`;
					for (let i of this.event.images) {
						this.imgs.push(this.apiUrl + i.contentUrl);
					}
				})
				.catch(err => {
					console.error(`getEventDetails, ${err}`);
					this.$router.push({name: "404"});
				})
		},
		seeMore() {
			this.eventDetailsLength = this.eventDetails.description.length;
		},

		/////////////////////
		showAllPhotos() {
			this.allPhotos = true;
		},
		hideAllPhotos() {
			this.allPhotos = false;
		},

		showFullImage(index) {
			console.log(index);
			this.index = index;
			this.visibleFullImage = true;
		},

		handlePrev(oldIndex, newIndex) {
			console.log('h-prev', oldIndex, newIndex);
		},
		handleNext(oldIndex, newIndex) {
			console.log('h-next', oldIndex, newIndex);
		},
		prev() {
			console.log('prev');
		},
		next() {
			console.log('next');
		},
		close() {
			console.log('close');
		}
		///////////////////////////
	}
}
</script>

<style scoped>

</style>
